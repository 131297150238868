import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>You can easily install with npm</p>
    <p>
  or download{" "}
  <a href="https://github.com/squidit/css/releases" target="_blank">
    here
  </a>
    </p>
    <p>{`or or use CDN `}<inlineCode parentName="p">{`https://www.jsdelivr.com/package/gh/squidit/css`}</inlineCode>{`*`}</p>
    <p>{`or use `}<inlineCode parentName="p">{`https://css-cdn.squidit.com.br`}</inlineCode>{`*`}</p>
    <p>{`-> Use the path from references above on CDN's to find the files. Exemple:
`}<inlineCode parentName="p">{`https://css-cdn.squidit.com.br/dist/css/squid.min.css`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install @squidit/css --save
`}</code></pre>
    <p>{`Just reference CSS `}<inlineCode parentName="p">{`/dist/css/squid.min.css`}</inlineCode>{` and Javascript `}<inlineCode parentName="p">{`/dist/js/squid.min.js`}</inlineCode>{` on your page or your framework.
If necessary remember to declare the fonts contained in the `}<inlineCode parentName="p">{`/dist/fonts`}</inlineCode>{` folder or change paths on `}<inlineCode parentName="p">{`squid.min.css`}</inlineCode>{` or `}<inlineCode parentName="p">{`squid.css`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <head>
    ...
    <link rel="stylesheet" href="path-to-files/dist/css/squid.min.css" />
  </head>
  <body>
    ...
    <script defer src="path-to-files/dist/js/squid.min.js"></script>
  </body>
</html>
`}</code></pre>
    <h2 {...{
      "id": "angular-install"
    }}>{`Angular Install`}</h2>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://github.com/squidit/ngx-css"
      }}>{`NGX-CSS`}</a></p>
    <h2 {...{
      "id": "reactjs-install"
    }}>{`React.js Install`}</h2>
    <p>{`After install, import CSS into your global style:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// Overwrite default font path
$fontsFolderPath: "@squidit/css/dist/fonts";
// Import all Framework Styles
@import "@squidit/css/src/scss/squid.scss";
`}</code></pre>
    <p>{`And in your `}<inlineCode parentName="p">{`index.{jsx,tsx}`}</inlineCode>{` on root just add:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if (typeof window !== "undefined") {
  require("@squidit/css/dist/js/squid.min.js");
}
`}</code></pre>
    <p>{`Obs: Probably components that need javascript will not work as they are loaded in onload event. They need some implementation within React.
A library for React will be developed soon.`}</p>
    <h2 {...{
      "id": "vuejs-install"
    }}>{`Vue.js Install`}</h2>
    <p>{`Soon`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      